import { globalLoggerToken } from "@/tokens";
import { applyTransaction } from "@datorama/akita";
import {
    Observable,
    Subscription,
    bufferTime,
    combineLatest,
    distinct,
    filter,
    map,
    mergeMap,
    switchMap,
    tap,
} from "rxjs";
import { inject, injectable } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { ProductMetafieldsApiServiceInterface } from "../api_services/ProductMetafieldsApiServiceInterface";
import { ProductMetafieldsStore } from "../stores/ProductMetafieldsStore";
import { productsApiServiceToken, productsStoreToken } from "../tokens";

@injectable()
export class ProductMetafieldsService {
    private bufferedProductIds$: Observable<number[]>;
    private subscription: Subscription | null = null;

    public constructor(
        @inject(productsApiServiceToken)
        private readonly productsApiService: ProductMetafieldsApiServiceInterface,
        @inject(productsStoreToken)
        private readonly productsStore: ProductMetafieldsStore,
        @inject(globalLoggerToken) private readonly logger: LoggerInterface
    ) {
        this.bufferedProductIds$ = this.productsStore
            ._select((state) => {
                return state.productStatuses;
            })
            .pipe(
                mergeMap((productStatuses) => productStatuses),
                filter((statusItem) => statusItem.status === "IN_QUEUE"),
                map((statusItem) => statusItem.productId),
                distinct(),
                bufferTime(200)
            );
    }

    public addProductForFetching(id: number) {
        this.logger.debug("ProductsService.addProductForFetching", {
            id,
        });
        this.productsStore.addProductInQueue(id);
    }

    public subscribe() {
        if (this.subscription) return;
        this.logger.debug("ProductMetafieldsService.subscribe");
        this.subscription = this.bufferedProductIds$
            .pipe(
                filter((ids) => ids.length >= 1),
                switchMap((ids: number[]) => {
                    return combineLatest(
                        ids.map((id) => {
                            return this.productsApiService.getProductMetafields(
                                id
                            );
                        })
                    );
                }),
                tap((results) => {
                    applyTransaction(() => {
                        results.forEach((result) => {
                            this.productsStore.add(result);
                        });
                    });
                })
            )
            .subscribe();
    }

    public stop() {
        this.logger.debug("ProductMetafieldsService.stop");
        this.subscription?.unsubscribe();
    }
}
