import { loginApplicationLoggerToken } from "@apps/login/tokens";
import { inject, singleton } from "tsyringe";

import type { LoggerInterface } from "@interfaces/LoggerInterface";

import type { CustomizationData, WidgetConfig } from "./types";

@singleton()
class CustomizationManager {
    private customizations = window.gw.customizations;
    private currentWidgetCustomization: CustomizationData = {};

    constructor(
        @inject(loginApplicationLoggerToken)
        private readonly logger: LoggerInterface
    ) {}

    public applyCustomization(config: WidgetConfig): void {
        if (!window.gw.customizations) return;

        this.customizations.forEach(([selectWidget, customizeWidget]) => {
            if (selectWidget(config)) {
                this.currentWidgetCustomization = customizeWidget(config);
                //logger
                this.logger?.debug("CustomizationManager.applyCustomization", {
                    currentCustomization: this.currentWidgetCustomization,
                });
                //call injectStyles
                if (config.shadowRoot) {
                    this.injectStyles(
                        this.currentWidgetCustomization.styleElement,
                        config.shadowRoot
                    );
                }
            }
        });
    }

    private injectStyles(
        styleElement: HTMLStyleElement | undefined,
        shadowRoot: ShadowRoot
    ): void {
        if (!styleElement) return;

        shadowRoot.appendChild(styleElement.cloneNode(true));
    }

    public async beforeWidgetInject(): Promise<void> {
        if (!this.currentWidgetCustomization?.beforeWidgetInject) return;

        const callback = this.currentWidgetCustomization.beforeWidgetInject;

        if (callback() instanceof Promise) {
            await callback();
        } else {
            void callback();
        }
    }

    public afterWidgetInject(): void {
        if (!this.currentWidgetCustomization?.afterWidgetInject) return;

        this.currentWidgetCustomization.afterWidgetInject();
    }
}

export default CustomizationManager;
