import { STATUS } from "@/constants/statuses";
import {
    EntityState,
    EntityStore,
    StoreConfig,
    arrayAdd,
    arrayUpdate,
} from "@datorama/akita";
import { injectable } from "tsyringe";

import ProductMetafieldsEntity from "../entities/ProductMetafieldsEntity";

const IN_QUEUE_STATUS = "IN_QUEUE";

type StatusItemKey = {
    productId: number;
    status: STATUS.LOADING | STATUS.READY | typeof IN_QUEUE_STATUS;
};

export interface ProductMetafieldsState
    extends EntityState<ProductMetafieldsEntity, number> {
    productStatuses: StatusItemKey[];
}

export function createInitialState(): ProductMetafieldsState {
    return {
        productStatuses: [],
    };
}

@injectable()
@StoreConfig({ name: "ProductMetafieldsStore" })
export class ProductMetafieldsStore extends EntityStore<ProductMetafieldsState> {
    constructor() {
        super(createInitialState());
    }

    public addProductInQueue(productId: number) {
        this.update(({ productStatuses }) => {
            if (
                !productStatuses.find(
                    (statusItem) => statusItem.productId === productId
                )
            ) {
                return {
                    productStatuses: arrayAdd(productStatuses, {
                        productId,
                        status: IN_QUEUE_STATUS,
                    }),
                };
            }
        });
    }

    public setLoadingStatus(productId: number) {
        this.update(({ productStatuses }) => ({
            productStatuses: arrayUpdate(
                productStatuses,
                ({ productId: _productId }) => _productId === productId,
                { status: STATUS.LOADING } as StatusItemKey
            ),
        }));
    }

    public setReadyStatus(productId: number) {
        this.update(({ productStatuses }) => ({
            productStatuses: arrayUpdate(
                productStatuses,
                ({ productId: _productId }) => _productId === productId,
                { status: STATUS.READY } as StatusItemKey
            ),
        }));
    }
}
