import { nullable, object, string, type } from "superstruct";

export const ShopifyGraphQLProductResponseSchema = object({
    product: type({
        product_wish_button: nullable(
            object({
                value: string(),
            })
        ),
    }),
});
