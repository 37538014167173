import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { ProductMetafieldsApiServiceInterface } from "./api_services/ProductMetafieldsApiServiceInterface";
import { ProductMetafieldsQuery } from "./queries/ProductMetafieldsQuery";
import { ProductMetafieldsService } from "./services/ProductMetafieldsService";
import { ProductMetafieldsStore } from "./stores/ProductMetafieldsStore";

export const mediaManagerApplicationLoggerToken = Symbol(
    "MediaManagerApplicationLoggerToken"
) as InjectionToken<LoggerInterface>;

export const mediaManagerAuthApiClientToken = Symbol(
    "mediaManagerAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const productsApiServiceToken = Symbol(
    "productsApiService"
) as InjectionToken<ProductMetafieldsApiServiceInterface>;

export const productsStoreToken = Symbol(
    "productsStore"
) as InjectionToken<ProductMetafieldsStore>;

export const productsServiceToken = Symbol(
    "productsService"
) as InjectionToken<ProductMetafieldsService>;

export const productsQueryToken = Symbol(
    "productsQuery"
) as InjectionToken<ProductMetafieldsQuery>;
