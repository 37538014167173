//Do not delete or edit enum value (REWARDS_WIDGET = "rewardsWidget"),
//                                                    ^^^^^^^^^^^^^
export enum REWARDS_WIDGET_NAMES {
    REWARDS_WIDGET = "rewardsWidget",
}

export enum REVIEWS_WIDGET_NAMES {
    REVIEWS_DRAWER_WIDGET = "reviewsDrawerWidget",
    PRODUCT_REVIEWS_WIDGET = "productReviewsWidget",
    REVIEWS_PAGE_WIDGET = "reviewsPageWidget",
    LEAVE_REVIEW_PAGE_WIDGET = "leaveReviewPageWidget",
    AVERAGE_REVIEWS_WIDGET = "averageReviewsWidget",
    REVIEWS_SLIDER_WIDGET = "reviewsSliderWidget",
    LISTING_AVERAGE_REVIEWS_WIDGET = "listingAverageReviewsWidget",
}

export enum WISHLIST_WIDGET_NAMES {
    ADD_TO_WISHLIST_PRODUCT_CARD_WIDGET = "addToWishlistProductCardWidget",
    ADD_TO_WISHLIST_PRODUCT_PAGE_WIDGET = "addToWishlistProductPageWidget",
    GO_TO_WISHLIST_PAGE_HEADER_WIDGET = "goToWishlistPageHeaderWidget",
    WISHLIST_PAGE_WIDGET = "wishlistPageWidget",
    WISHLIST_DRAWER_WIDGET = "wishlistDrawerWidget",
}

export enum MEDIA_MANAGER_NAMES {
    DESIGN_FEED_WIDGET = "designFeedWidget",
}

export type WIDGET_NAMES_TYPE =
    | REWARDS_WIDGET_NAMES
    | REVIEWS_WIDGET_NAMES
    | WISHLIST_WIDGET_NAMES
    | MEDIA_MANAGER_NAMES;
