import { GraphQLApiClient } from "@lib/GraphQLApiClient/GraphQLApiClient";
import { gql } from "graphql-tag";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { create } from "superstruct";
import { singleton } from "tsyringe";

import ProductMetafieldsEntity from "../entities/ProductMetafieldsEntity";
import { ProductMetafieldsApiServiceInterface } from "./ProductMetafieldsApiServiceInterface";
import { ShopifyGraphQLProductResponseSchema } from "./responses/ShopifyGraphQLProductResponseSchema";

@singleton()
export class ShopifyGraphQLProductMetafieldsApiService
    implements ProductMetafieldsApiServiceInterface
{
    constructor(private readonly graphQLApiClient: GraphQLApiClient) {}
    public getProductMetafields(
        productId: number
    ): Observable<ProductMetafieldsEntity> {
        const document = gql`
            query {
                product(id: "gid://shopify/Product/${productId}") {
                    product_wish_button: metafield(namespace: "custom", key: "product_wish_button") {
                        value
                    }
                }
            }
        `;
        return this.graphQLApiClient.request(document).pipe(
            map((data): ProductMetafieldsEntity => {
                const response = create(
                    data,
                    ShopifyGraphQLProductResponseSchema
                );

                return {
                    id: productId,
                    product_wish_button:
                        response.product.product_wish_button?.value === "true",
                };
            })
        );
    }
}
