// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WISHLIST (prefix: 'wl') */
:root,
:host {
    --gw-wl-primary-link-color: #3772ff;

    --gw-wl-email-hover: #ffb02e;
    --gw-wl-facebook-hover: #4267b2;
    --gw-wl-twitter-hover: #141416;
    --gw-wl-pinterest-hover: #e60023;
    --gw-wl-whatsapp-hover: #25d366;
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/wishlist.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;;IAEI,mCAAmC;;IAEnC,4BAA4B;IAC5B,+BAA+B;IAC/B,8BAA8B;IAC9B,gCAAgC;IAChC,+BAA+B;AACnC","sourcesContent":["/* WISHLIST (prefix: 'wl') */\n:root,\n:host {\n    --gw-wl-primary-link-color: #3772ff;\n\n    --gw-wl-email-hover: #ffb02e;\n    --gw-wl-facebook-hover: #4267b2;\n    --gw-wl-twitter-hover: #141416;\n    --gw-wl-pinterest-hover: #e60023;\n    --gw-wl-whatsapp-hover: #25d366;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
