import { Infer, object, string, type } from "superstruct";

export const BrandingOptionsSchema = object({
    login: object({
        brand: string(),
        text: string(),
        background: string(),
    }),
    reviews: object({
        reviews_main_color: string(),
        reviews_text_color: string(),
        reviews_stars_color: string(),
    }),
    rewards: object({
        rewards_main_color: string(),
        rewards_primary_text_color: string(),
    }),
    wishlist: object({
        wishlist_main_color: string(),
        wishlist_text_color: string(),
        wishlist_heart_color: string(),
    }),
});

export const AdditionalInfoBrandingSchema = type({
    GW_BRANDING_OPTIONS: BrandingOptionsSchema,
});

export type AdditionalInfoBranding = Infer<typeof AdditionalInfoBrandingSchema>;
