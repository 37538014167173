import { QueryEntity } from "@datorama/akita";
import { injectable } from "tsyringe";

import {
    ProductMetafieldsState,
    ProductMetafieldsStore,
} from "../stores/ProductMetafieldsStore";

@injectable()
export class ProductMetafieldsQuery extends QueryEntity<ProductMetafieldsState> {
    public constructor(
        private readonly productMetafieldsStore: ProductMetafieldsStore
    ) {
        super(productMetafieldsStore);
    }

    all$ = this.selectAll();
}
