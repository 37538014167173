import { globalLoggerToken } from "@/tokens";
import { GraphQLApiClient } from "@lib/GraphQLApiClient/GraphQLApiClient";
import { DependencyContainer, instanceCachingFactory } from "tsyringe";

import { ShopifyGraphQLProductMetafieldsApiService } from "./api_services/ShopifyGraphQLProductMetafieldsApiService";
import { ProductMetafieldsQuery } from "./queries/ProductMetafieldsQuery";
import { ProductMetafieldsService } from "./services/ProductMetafieldsService";
import { ProductMetafieldsStore } from "./stores/ProductMetafieldsStore";
import {
    productsApiServiceToken,
    productsQueryToken,
    productsServiceToken,
    productsStoreToken,
} from "./tokens";

export class ProductMetafieldsModule {
    registerProviders(di: DependencyContainer) {
        di.register(productsApiServiceToken, {
            useFactory: instanceCachingFactory(() => {
                const graphtQLApiClient = di.resolve(GraphQLApiClient);

                if (graphtQLApiClient) {
                    return new ShopifyGraphQLProductMetafieldsApiService(
                        graphtQLApiClient
                    );
                }
            }),
        });
        di.register(productsStoreToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductMetafieldsStore();
            }),
        });
        di.register(productsQueryToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductMetafieldsQuery(
                    di.resolve(productsStoreToken)
                );
            }),
        });
        di.register(productsServiceToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductMetafieldsService(
                    di.resolve(productsApiServiceToken),
                    di.resolve(productsStoreToken),
                    di.resolve(globalLoggerToken)
                );
            }),
        });
        di.resolve(productsServiceToken).subscribe();
    }
}
