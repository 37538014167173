import { GW_ADDITIONAL_INFO_SELECTOR } from "@/constants";
import { APP_NAMES } from "@/constants/app-names";
import { getJsonFromScript } from "@lib/utils";
import { Infer, create } from "superstruct";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { loginApplicationLoggerToken } from "../../apps/login/tokens";
import {
    AdditionalInfoBrandingSchema,
    BrandingOptionsSchema,
} from "./BrandingSchemas";
import type { AdditionalInfoBranding } from "./BrandingSchemas";

@singleton()
class BrandingProvider {
    private brandingOptions: Infer<typeof BrandingOptionsSchema>;
    constructor(
        @inject(loginApplicationLoggerToken)
        private readonly logger: LoggerInterface
    ) {
        const additionalInfoData = getJsonFromScript(
            GW_ADDITIONAL_INFO_SELECTOR
        );

        const additionalInfo = create(
            additionalInfoData,
            AdditionalInfoBrandingSchema
        );
        this.brandingOptions = additionalInfo.GW_BRANDING_OPTIONS;

        this.logger.info("branding provider is ready", {
            brandingOptions: this.brandingOptions,
        });
    }

    getBrandingOptions(
        app: keyof AdditionalInfoBranding["GW_BRANDING_OPTIONS"]
    ): AdditionalInfoBranding["GW_BRANDING_OPTIONS"][typeof app] | null {
        if (!this.brandingOptions) return null;

        return this.brandingOptions[app];
    }

    public getWishlistBrandingOptions() {
        return this.brandingOptions[APP_NAMES.WISHLIST];
    }

    public getReviewsBrandingOptions() {
        return this.brandingOptions[APP_NAMES.REVIEWS];
    }

    public getRewardsBrandingOptions() {
        return this.brandingOptions[APP_NAMES.REWARDS];
    }

    public getLoginBrandingOptions() {
        return this.brandingOptions[APP_NAMES.LOGIN];
    }
}

export default BrandingProvider;
